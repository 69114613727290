<template>
  <div class="site-wrapper">
    <div class="a-main a-main--home bg-linear">
      <div class="container">
        <div class="reset-text">Reset Password</div>
        <div class="col-md-12 reset-form">
          <Error :errors="errors" />
          <form method="POST" class="my-5" @submit.prevent="reset">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email"
                v-model="form.email"
                required
              />
            </div>
            <div class="form-group">
              <label for="code">Code</label>
              <input
                type="number"
                class="form-control"
                id="code"
                placeholder="Code"
                v-model="form.code"
                required
              />
            </div>
            <div class="form-group">
              <label for="password">New Password</label>
              <VuePassword
                v-model="form.password"
                placeholder="Password"
                id="password"
                type="password"
                :disableStrength="true"
                required
              />
            </div>
            <div class="form-group">
              <label for="password_confirmation">Confirm Password</label>
              <VuePassword
                v-model="form.password_confirmation"
                placeholder="Confirm password"
                id="password_confirmation"
                type="password"
                :disableStrength="true"
                required
              />
            </div>
            <button type="submit" class="btn btn-green">Reset password</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VuePassword from "vue-password";
import { mapActions, mapState } from "vuex";
import Error from "@/components/Error.vue";
export default {
  components: {
    Error,
    VuePassword,
  },
  data() {
    return {
      error: false,
      errors: [],
      form: {
        email: null,
        code: null,
        password: null,
        password_confirmation: null,
      },
    };
  },

  created() {
    this.form.email = this.$route.params.email;
  },
  methods: {
    ...mapState(["loading"]),
    ...mapActions(["setLoading"]),
    ...mapActions(["setIsAuthenticated"]),
    ...mapActions("user", ["completePasswordReset"]),

    reset() {
      if (this.checkForm()) {
        this.setLoading(true);
        this.completePasswordReset(this.form)
          .then((res) => {
            if (res.error) {
              this.$toaster.error(res.message);
              this.setLoading("error");
            } else {
              this.setLoading(false);
              this.$toaster.success(res.message);
              this.$router.push("/login");
            }
            this.setLoading(false);
          })
          .catch((err) => {
            this.$toaster.error(err);
          });
      }
    },

    checkForm() {
      if (
        this.form.code &&
        this.form.email &&
        this.form.password &&
        this.form.password_confirmation &&
        this.form.password == this.form.password_confirmation
      ) {
        return true;
      }

      this.errors = [];
      if (!this.form.email) {
        this.errors.push("Email required.");
      }
      if (!this.form.code) {
        this.errors.push("Code required.");
      }
      if (!this.form.password) {
        this.errors.push("Password required.");
      }
      if (!this.form.password_confirmation) {
        this.errors.push("Password confirmation required.");
      }
      if (
        this.form.password &&
        this.form.password_confirmation &&
        this.form.password != this.form.password_confirmation
      ) {
        this.errors.push("Password and password confirmation doesnot match.");
      }
    },
  },
};
</script>

<style scoped>
.reset-text {
  text-align: center;
  margin-top: 60px;
  font-size: 30px;
  color: white;
  /* color: rgb(4 188 197); */
}
.reset-form {
  margin-top: 110px;
}
.btn-green {
  width: 100%;
}

.alert {
  border: 2px solid transparent;
}
label {
  color: white;
}
</style>
